import React from 'react';
import './Ruler.css';

import RularTry from './RularTry/RularTry';

const Ruler = () => {
 

  return (
    <>
    <div>
      
      <RularTry/>
    
      </div>
    </>
  );
};

export default Ruler;
